* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "PT Sans", sans-serif; */
  /* font-family: "Ramaraja", serif; */
  /* font-family: 'Rajdhani', sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}
/* .pageBackgroundColor {
  background-color: ;
} */
.navbar {
  background-color: transparent;
}

.navbar.colorChange {
  /* background-color: #030514; */
  /* background-color: #c3cdd4; */
  background-color: white;
  color: #000;
}

.navbarText {
  color: #030514;
  transition: 1s;
}

.navbarText.textColorChange {
  /* color: #88e0ef; */
  color: black;
}

.underline {
  height: 4px;
  width: 4rem;
  background-color: lightblue;
  margin-top: 10px;
  margin-bottom: 10px;
}

.borderBottom {
  border-bottom: 2px solid #000;
  margin-left: 20px;
  margin-right: 20px;
}

.imageLogo {
  height: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navbarImageLogo {
  flex-direction: row;
  height: 60px;
}

.row {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
}
